<template>
  <div>
     <div class="breadcrumbs" data-aos="fade-in">
      <div class="container">
        <h2>{{$t("register")}}</h2>
      </div>
    </div> 
    <section class="contact events">
      <div class="container" data-aos="fade-up">
        <div class="d-flex justify-content-center row mt-5">
           <div class="card">
              <div class="card-img">
                <img src="/assets/img/banner1.png" alt="...">
              </div>
              <div class="card-body">
                <div class="col-lg-12 mt-5 mt-lg-0">
                  <form role ="form" class="register-form" @submit.prevent="register">
                    <validation-observer ref="verifyForm">

                      <div class="form-row row my-3">
                        <div class="col-lg-2 form-group">
                            <label for="name">{{$t("name")}}</label>
                        </div>
                        <div class="col-lg-10 form-group">
                          <validation-provider rules="required" name="name" #default="{ errors }" vid="name">
                            <input type="text" class="form-control" :class="errors.length > 0 ? 'is-invalid':null" id="name" v-model="form.name" :placeholder="$t('enter-your-name')" :aria-label="$t('enter-your-name')" >
                            <div class="row">
                                <div class="col-md-12">
                                    <small class="text-danger">{{errors[0] }}</small>
                                </div>
                            </div>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-row row my-3">
                        <div class="col-lg-2 form-group">
                            <label for="email">{{$t("email")}}</label>
                        </div>
                        <div class="col-lg-10 form-group">
                          <validation-provider rules="required|email" name="email" #default="{ errors }" vid="email">
                            <input type="email" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  id="email" v-model="form.email" :placeholder="$t('enter-your-email')" :aria-label="$t('enter-your-email')" >
                            <div class="row">
                                <div class="col-md-12">
                                    <small class="text-danger">{{errors[0] }}</small>
                                </div>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                      
                      <div class="form-row row my-3">
                        <div class="col-lg-2 form-group">
                          <label for="password">{{$t("password")}}</label>
                        </div>
                        <div class="col-lg-10 form-group">
                          <validation-provider rules="password|required|min:8" name="password" #default="{ errors }" vid="password">
                              <input type="password" class="form-control" :class="errors.length > 0 ? 'is-invalid':null" v-model="form.password" id="password" :placeholder="$t('enter-password')" :aria-label="$t('enter-password')" >
                              <div class="row">
                                <div class="col-md-12">
                                    <small class="text-danger">{{errors[0] }}</small>
                                </div>
                              </div>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="form-row row my-3">
                        <div class="col-lg-2 form-group">
                          <label for="confirm-password">{{$t("confirm-password")}}</label>
                        </div>
                        <div class="col-lg-10 form-group">
                           <validation-provider rules="password|required|min:8" name="password confirmation" #default="{ errors }" vid="password confirmation">
                            <input type="password" class="form-control" :class="errors.length > 0 ? 'is-invalid':null" v-model="form.passwordConfirmation" id="password_confirmation" :placeholder="$t('confirm-password')" :aria-label="$t('confirm-password')" >
                              <div class="row">
                                <div class="col-md-12">
                                    <small class="text-danger">{{errors[0] }}</small>
                                </div>
                              </div>
                           </validation-provider>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center">
                          <div class="row">
                             <validation-provider rules="" name="token" #default="{ errors }" vid="token">
                              <vue-recaptcha :language="$i18n.locale" v-model="form.token" style="margin: 0 auto;display: table" class="mb-3" :class="errors.length > 0 ? 'is-invalid':null" @verify="onVerify" :sitekey="siteKey" :loadRecaptchaScript="true">
                              </vue-recaptcha>
                              <div class="row">
                                <div class="col-md-12">
                                    <small class="text-danger">{{errors[0] }}</small>
                                </div>
                              </div>
                             </validation-provider>
                          </div>
                          
                      </div>

                      <div class="d-flex justify-content-center">
                        <button type="submit">{{$t("submit")}}</button>
                      </div>

                    </validation-observer>

                  </form>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <div v-html="procedure"></div> -->

  </div>
</template>

<script>
import VueRecaptcha from  "vue-recaptcha";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '../utils/validations/validations'
import {toast} from "../utils/alert.util";
export default {
  components : {VueRecaptcha, ValidationProvider, ValidationObserver},
  metaInfo() {
    return { 
      title: `${this.$t("form-submission")} - ${this.$t("ace-students-awards")}`,
      // htmlAttrs: {
      //   lang: this.$i18n.locale,
      //   amp: undefined
      // },
      meta: [
        { name: 'description', content:  ''},
        { property: 'og:title', content: ""},
        { property: 'og:site_name', content: ''},
        {property: 'og:type', content: 'website'},    
        {name: 'robots', content: 'index,follow'} 
      ]
    }
  },
    data(){
        return {
          form : {},
          required,
          errors : {},
          siteKey : process.env.VUE_APP_RECAPTCHA_SITE_KEY,
          aces : {},
          loggedIn : localStorage.getItem("user-token") ? true : false
        }
    },
    methods : {
      register(){
        this.$refs.verifyForm.validate().then(success => {
          if(success){
            this.form.locale = this.$i18n.locale;
            this.form.messageBody = this.$t('email-address-verification-content');
            this.form.application_base_url = process.env.VUE_APP_FRONTEND_BASE_URL;
            const loading = this.$vs.loading({
              color : "#FF1234",
              type : "circles",
              text : this.$t("processing-please-wait")
            });
            this.$auth.register({data: this.form})
            .then(() => {
              loading.close();
              this.form = {};
              toast.show({
                icon : "success",
                title : this.$t("submitted-successful"),
                message : this.$t("registration-successful")
            });
            }).catch(e => {
            loading.close();
              switch (e.response.status) {
                case 422:
                  console.log(e.response.data);
                  this.errors = e.response.data.errors;
                  toast.show({
                      icon : "error",
                      title : this.$t(e.response.data.message)
                  });
                  break;
              
                default:
                  toast.show({
                    icon : "error",
                    title : this.$t("something-went-wrong", {code : e.response.status}),
                  });
                  break;
              }
            });
          }
          else{
            toast.show({
                icon : "error",
                title : this.$t('fields-incorrectly-filled')
            });
          }
        });
        
      },

      onVerify: function (response) {
      this.form.token = response;
      },
    },
    mounted(){
      if(this.loggedIn){
        this.$router.push({name : "Application"})
      }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>