var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"breadcrumbs",attrs:{"data-aos":"fade-in"}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v(_vm._s(_vm.$t("register")))])])]),_c('section',{staticClass:"contact events"},[_c('div',{staticClass:"container",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"d-flex justify-content-center row mt-5"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-lg-12 mt-5 mt-lg-0"},[_c('form',{staticClass:"register-form",attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('validation-observer',{ref:"verifyForm"},[_c('div',{staticClass:"form-row row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("name")))])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"required","name":"name","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"text","id":"name","placeholder":_vm.$t('enter-your-name'),"aria-label":_vm.$t('enter-your-name')},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}])})],1)]),_c('div',{staticClass:"form-row row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("email")))])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"email","id":"email","placeholder":_vm.$t('enter-your-email'),"aria-label":_vm.$t('enter-your-email')},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}])})],1)]),_c('div',{staticClass:"form-row row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t("password")))])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"password|required|min:8","name":"password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"password","id":"password","placeholder":_vm.$t('enter-password'),"aria-label":_vm.$t('enter-password')},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}])})],1)]),_c('div',{staticClass:"form-row row my-3"},[_c('div',{staticClass:"col-lg-2 form-group"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v(_vm._s(_vm.$t("confirm-password")))])]),_c('div',{staticClass:"col-lg-10 form-group"},[_c('validation-provider',{attrs:{"rules":"password|required|min:8","name":"password confirmation","vid":"password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.passwordConfirmation),expression:"form.passwordConfirmation"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"type":"password","id":"password_confirmation","placeholder":_vm.$t('confirm-password'),"aria-label":_vm.$t('confirm-password')},domProps:{"value":(_vm.form.passwordConfirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "passwordConfirmation", $event.target.value)}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}])})],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":"","name":"token","vid":"token"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-recaptcha',{staticClass:"mb-3",class:errors.length > 0 ? 'is-invalid':null,staticStyle:{"margin":"0 auto","display":"table"},attrs:{"language":_vm.$i18n.locale,"sitekey":_vm.siteKey,"loadRecaptchaScript":true},on:{"verify":_vm.onVerify},model:{value:(_vm.form.token),callback:function ($$v) {_vm.$set(_vm.form, "token", $$v)},expression:"form.token"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}])})],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("submit")))])])])],1)])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-img"},[_c('img',{attrs:{"src":"/assets/img/banner1.png","alt":"..."}})])}]

export { render, staticRenderFns }